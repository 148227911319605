<template>
  <div>
    <b-overlay :show="isBusy" :opacity="0.88">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Please Wait....</strong>
        </div>
      </template>
      <router-view></router-view>
    </b-overlay>
  </div>
</template>

<script>
import { bus } from '../../main';
import { APP_TYPES,STORES} from "../shared";
export default {
  computed: {
    initialized() {
      return this.$store.state.depsInitialized;
    },
  },
  data() {
    return {
      type: APP_TYPES.DEPOSIT,
      isBusy: false
    };
  },
  mounted() {
    if (this.$route.name == "deposits") {
      if (!this.initialized) {
        this.getApplications();
      }
    }
    bus.$on("reload-deposits",()=>{
      this.getApplications();
    });
  },
  methods:{
    getApplications() {
      this.isBusy = true;
      this.$store.dispatch(STORES[this.type] + "/initApplications").then(() => {
        this.isBusy = false;
        this.$store.commit("set_depsInitialized");
      });
    },
  }
};
</script>

<style>
</style>